'use client'

import { Container, Grid, Alert } from '@mui/material'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const NotFoundPage = () => {
	// Varaibles
	const { t } = useTranslation()

	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" width="100vw" height="100vh">
			<Container maxWidth="xs">
				<Grid container direction="column" justifyContent="center" alignItems="center" sx={{ width: 1 }}>
					<Alert severity="error">
						<strong>404</strong>
						&nbsp;|&nbsp;
						{t('common:message.notFound')}
					</Alert>
				</Grid>
			</Container>
		</Grid>
	)
}

export default NotFoundPage
